import React from "react"
import PropTypes from "prop-types"
import styles from "../styles/content.module.styl"
import { Options } from "./WhoIsThisToolFor"

const OPTIONS = [
  {
    title:
      "Direct oral anticoagulants (DOACs) - apixaban (Eliquis), dabigatran (Pradaxa), edoxaban (Lixiana or Savaysa), or rivaroxaban (Xarelto) pills",
  },
  {
    title:
      "Low molecular weight heparin (LMWH) - dalteparin (Fragmin) or enoxaparin (Clexane or Lovenox), or tinzaparin (Innohep) injections",
  },
  {
    title:
      "Vitamin K antagonists (VKA) - warfarin (Coumadin) or other (Jantoven) pills",
  },
]

const OPTIONS_2 = [{ title: "Aspirin pills or liquid." }]

const Treatment = props => {
  return (
    <div>
      <h2 className={styles.sectionTitle}>Treatment</h2>
      <div className={styles.mb20}>
        To help prevent another DVT or PE, you will get (or continue to get) one
        of the following types of blood thinner medicines:
      </div>

      <Options
        optionsTitle="Anticoagulants"
        optionsKey={"treatment_options"}
        options={OPTIONS}
        inputType="checkbox"
      />
      <Options
        optionsTitle="Antiplatelets"
        optionsKey={"treatment_options2"}
        options={OPTIONS_2}
        inputType="checkbox"
      />
    </div>
  )
}

Treatment.propTypes = {}

export default Treatment
