import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Options } from "./WhoIsThisToolFor"
import styles from "../styles/content.module.styl"
import { printElement } from "../lib/utils"

const OPTIONS_1 = [
  {
    title: "Your risk of having another PE or DVT",
  },
  {
    title: "Your risk of major bleeding.",
  },
]

const OPTIONS_2 = [
  { title: "Your willingness and ability to continue taking blood thinners" },
  {
    title:
      "The availability of blood thinners and your ability to take them (by mouth or injection)",
  },
  {
    title:
      "The schedule for taking your blood thinners and how it works for you and your family",
  },
  {
    title:
      "The need for regular blood testing to check how well your blood thinner is working",
  },
  { title: "The costs of taking blood thinners" },
  {
    title:
      "Whether you should continue taking the same medicine or switch to another blood thinner",
  },
  {
    title:
      "Any lifestyle changes you should continue making while on blood thinners ",
  },
  {
    title: "Any worries or anxiety you may have about being on blood thinners",
  },
  {
    title:
      "What healthcare support will be available for you and your family. ",
  },
]

const TopicsThatYouShouldDiscuss = props => {
  return (
    <div>
      <div className={classNames(styles.flexRow, styles.alignCenter)}>
        <h2 className={classNames(styles.sectionTitle, styles.grow)}>
          Topics that you should discuss with your healthcare provider
        </h2>
        <button
          onClick={() => printElement("printable-section")}
          className={classNames(styles.mt40, styles.printBtn)}
        >
          Print
        </button>
      </div>
      <div id="printable-section">
        <div className="noScreen">
          <h2>
            After being treated for your first unprovoked blood clot, should you
            continue to take blood thinners indefinitely or stop?
          </h2>
          <h3>Topics that you should discuss with your healthcare provider</h3>
        </div>
        <div className={styles.mb20}>
          It will be important to speak with your healthcare professional to
          find out:
        </div>
        <Options optionsKey={"opt1"} options={OPTIONS_1} inputType="checkbox" />
        <div className={classNames(styles.mb20, styles.mt20)}>
          You can also speak with your healthcare provider about the following
          issues:
        </div>
        <Options optionsKey={"opt2"} options={OPTIONS_2} inputType="checkbox" />
      </div>
    </div>
  )
}

TopicsThatYouShouldDiscuss.propTypes = {}

export default TopicsThatYouShouldDiscuss
