import classNames from "classnames"
import React from "react"
import _ from "lodash"
import styles from "../styles/content.module.styl"

const OPTIONS = [
  {
    title:
      "You have a new DVT or PE that was diagnosed as <b>unprovoked</b>, meaning:",
    options: [
      {
        title:
          "You do <b><i>not</i></b> have any risk factors for blood clots, such as major surgery in past 3 months, long bed rest or inability to move (3 days or more), hormone use (birth control pills, hormone replacement), pregnancy, or specific long term diseases such as cancer, inflammatory bowel disease, autoimmune disorder, or chronic infection; and/or",
      },
      { title: "It is <b><i>not</i></b> known why you had a DVT or PE." },
    ],
  },
  {
    title:
      "You have been taking blood thinners for about 3 to 6 months to treat DVT or PE",
  },
  {
    title: "You are <b><i>not</i></b> at high risk for bleeding complications.",
  },
]

export const Options = ({ optionsTitle, options, optionsKey, inputType }) => {
  return (
    <div>
      {!_.isEmpty(optionsTitle) && (
        <div className={classNames(styles.mb10, styles.ml10)}>
          <i>{optionsTitle}</i>
        </div>
      )}
      <ul
        className={classNames(styles.unstyledList, styles.listWithRadioButtons)}
      >
        {_.map(options, ({ title, options: subOptions }) => (
          <li>
            <div>
              <label>
                <input type={inputType} name={optionsKey} />
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </label>
            </div>
            {!_.isEmpty(subOptions) && (
              <Options
                optionsKey={_.camelCase(title)}
                options={subOptions}
                inputType="checkbox"
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}

const WhoIsThisToolFor = ({ title }) => {
  return (
    <div>
      <h2 className={styles.sectionTitle}>{title}</h2>
      <Options
        options={OPTIONS}
        optionsKey="whoIsThisFor"
        inputType="checkbox"
      />
      <div className={styles.mt20}>
        If you are unsure whether this decision aid is for you, it will be
        important to check with your healthcare provider.
      </div>
    </div>
  )
}

export default WhoIsThisToolFor
