import React from "react"
import { shape, number, func, string } from "prop-types"
import _ from "lodash"
import classNames from "classnames"
import RangeInput from "../common/RangeInput"
import RoundCaption from "../common/RoundCaption"
import styles from "../../styles/outcomesValueAssessment.module.styl"

const OutcomeText = props => {
  const { name, textBefore, textAfter } = props
  return (
    <h3>
      <span>{textBefore}</span>
      <a> {name} </a>
      <span>{textAfter} </span>
    </h3>
  )
}

OutcomeText.propTypes = {
  textAfter: string.isRequired,
  textBefore: string.isRequired,
  name: string.isRequired,
}

OutcomeText.defaultProps = {
  textBefore: "In my opinion having a",
  textAfter: "is equivalent to:",
}

class OutcomeValueAssessment extends React.PureComponent {
  static propTypes = {
    className: string,
    outcome: shape({
      key: string.isRequired,
      marker: string.isRequired,
    }).isRequired,
    maxValue: number,
    minValue: number,
    onChange: func.isRequired,
    value: number,
  }

  static defaultProps = {
    value: 50,
    minValue: 0,
    maxValue: 100,
  }

  constructor(props) {
    super(props)
    this.emitChange = this.emitChange.bind(this)
    this.handleInputValueChange = this.handleInputValueChange.bind(this)
  }

  emitChange(value) {
    this.props.onChange({
      outcomeId: this.props.outcome.key,
      value: value,
    })
  }

  handleInputValueChange(evt) {
    const value = Number(evt.target.value)
    if (_.isFinite(value)) {
      const clampedValue = _.clamp(value, 0, 100)

      this.emitChange(clampedValue)
    } else {
      return evt.preventDefault()
    }
  }

  render() {
    const { value, outcome, className, minValue, maxValue } = this.props
    const containerClassName = classNames(
      styles.outcomeValueAssessment,
      className
    )
    return (
      <div className={containerClassName}>
        <div className={styles.outcomeValueAssessmentCaption}>
          <OutcomeText
            name={outcome.marker}
            textBefore={outcome.markerPrefix}
          />
          <input
            onChange={this.handleInputValueChange}
            type="text"
            value={value}
          />
        </div>
        <RoundCaption>
          <RangeInput
            onChange={this.emitChange}
            min={minValue}
            max={maxValue}
            step={5}
            scaleBarClass={styles.markerStateScale}
            tickMarkStep={50}
            tickMarkLabels={{
              0: "Dead",
              100: "Perfect Health",
            }}
            value={value}
          />
        </RoundCaption>
      </div>
    )
  }
}

export default OutcomeValueAssessment
